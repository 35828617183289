<template>
  <div v-loading="loadingFlag" class="page-card-demo  page-info-content amount-hxqy-detail">
    <div class="title">
      额度信息
    </div>
    <ul class="info-list clear">
      <li class="flex-box">
        <span class="label">核心企业：</span><span class="info flex1">{{ detailList.coreName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">资金方：</span><span class="info flex1">{{ detailList.bankName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">所属集团：</span><span class="info flex1">{{ detailList.groupName }}</span>
      </li>
    </ul>
    <div class="title">
      <span>核心企业额度</span>
      <el-button v-if="detailList.quotaStat==0 || isEdit" size="mini" type="primary" class="table-button-e" @click="amountEdit()">
        调整
      </el-button>
    </div>
    <ul class="info-list clear">
      <li class="flex-box">
        <span class="label">总额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaSum|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">已用额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaUsed|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">冻结额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaDisabled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">可用额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaAbled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">核心企业额度到期日：</span><span
          class="info flex1"
        >{{ detailList.quotaDue|date }}</span>
      </li>
      <li class="flex-box">
        <span class="label">额度状态：</span><span class="info flex1">{{ detailList.quotaStat==0?'有效':'失效' }}</span>
      </li>
      <li class="flex-box">
        <span class="label">供应商额度(元)：</span><span
          class="info flex1"
        >{{ detailList.defCustQuoat|formatMoney }}</span>
      </li>
    </ul>
    <div class="title">
      <span>产品专项额度</span>
      <el-button v-if="detailList.quotaStat==0 || isEdit" size="mini" type="primary" class="table-button-e" @click="productAdd()">
        添加
      </el-button>
    </div>
    <div class="table-box">
      <el-table :border="true" :data="detailList.prodList" class="br-table">
        <el-table-column label="产品编码" prop="prodId" min-width="100" />
        <el-table-column label="资金方产品编码" prop="bankProdCode" min-width="100" />
        <el-table-column label="版本" prop="bankVersion" min-width="120" />
        <el-table-column label="产品名称" prop="prodName" min-width="120" />
        <el-table-column label="产品类别" prop="prodCate" min-width="120">
          <template slot-scope="scope">
            {{ $code("proCate",scope.row.prodCate) }}
          </template>
        </el-table-column>
        <el-table-column label="产品品种" prop="prodType" min-width="120">
          <template slot-scope="scope">
            {{ $childrenCode("proCate",scope.row.prodCate,scope.row.prodType) }}
          </template>
        </el-table-column>
        <el-table-column label="产品总额度（元）" prop="quotaProdSum" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaProdSum|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="产品已用额度（元）" prop="quotaProdUsed" min-width="130">
          <template slot-scope="scope">
            {{ scope.row.quotaProdUsed|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="冻结额度（元）" prop="quotaProdDisabled" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.quotaProdDisabled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="可用额度（元）" prop="quotaProdAbled" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaProdAbled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" header-align="left" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="text" class="" @click="productDetail(scope.row)">
              详情
            </el-button>
            <el-button v-if="detailList.quotaStat==0" size="mini" type="text" class="" @click="productEdit(scope.row)">
              额度调整
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--产品专项额度弹窗-->
    <el-dialog :title="productTitle" :visible.sync="productDialogVisible" class="dialog">
      <el-form ref="productDialog" :model="productDialogList" :rules="productDialogRules" label-width="150px" @submit.native.prevent>
        <el-form-item label="产品名称" prop="prodId">
          <el-select
            v-if="productTitle==='添加产品专项额度'"
            v-model="productDialogList.prodId"
            placeholder="产品名称"
            class="br-input"
            @change="productChange"
          >
            <el-option
              v-for="(item,index) in productNameList"
              :key="index"
              :label="item.proName"
              :value="item.proId"
            />
          </el-select>
          <el-select v-else v-model="productDialogList.prodId" placeholder="产品名称" class="br-input" disabled>
            <el-option
              v-for="(item,index) in productNameList"
              :key="index"
              :label="item.proName"
              :value="item.proId"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="productDialogList.bankProdCode" label="资方产品编码">
          {{ productDialogList.bankProdCode }}
        </el-form-item>
        <el-form-item label="总额度(元)" prop="quotaProdSum">
          <el-input
            v-model="productDialogList.quotaProdSum"
            class="br-input"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button class="br-btn template-reset-btn" size="small" @click="productDialogVisible = false">
          取 消
        </el-button>
        <el-button class="br-btn template-search-btn" size="small" type="primary" @click="productSubmit()">
          确认提交
        </el-button>
      </div>
    </el-dialog>
    <!--额度调整弹窗-->
    <el-dialog title="额度调整" :visible.sync="amountDialogVisible" class="dialog">
      <el-form ref="amountDialog" :model="amountDialogList" :rules="amountDialogRule" label-width="150px" @submit.native.prevent>
        <el-form-item label="核心企业" prop="coreName">
          <el-select v-model="amountDialogList.coreId" placeholder="核心企业" class="br-select-large" :disabled="isEdit" @change="coreEnterPriseListChange">
            <el-option
              v-for="(item,index) in coreEnterPriseList"
              :key="index"
              :label="item.cmp_name"
              :value="item.cmp_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="资金方" prop="bankName">
          <el-select v-model="amountDialogList.bankId" placeholder="资金方" class="br-select-large" :disabled="isEdit" @change="startFuncListChange">
            <el-option
              v-for="(item,index) in bankList"
              :key="index"
              :label="item.cmpName"
              :value="item.cmpId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="集团模式" prop="groupType">
          <el-radio-group v-model="amountDialogList.groupType" class="br-radio-large" :disabled="isEdit" @change="groupTypeChange">
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio :label="0">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="amountDialogList.groupType==1" label="集团名称" prop="group">
          <el-select
            v-model="amountDialogList.group"
            class="br-select-large"
            :disabled="isEdit"
            value-key="groupUnicode"
            @change="groupNameChange"
          >
            <el-option
              v-for="(item,index) in groupSelectList"
              :key="index"
              :label="item.groupName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="总额度(元)" prop="quotaSum">
          <el-input
            v-model="amountDialogList.quotaSum"
            class="br-input-large"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
            @input="quotaSumInput"
            @change="quotaChange"
          />
        </el-form-item>
        <el-form-item label="核心企业额度到期日" prop="quotaDue">
          <el-date-picker
            v-model="amountDialogList.quotaDue"
            type="date"
            placeholder="核心企业额度到期日"
            class="br-date-large"
            :picker-options="dateOptions"
            value-format="timestamp"
          />
        </el-form-item>
        <el-form-item label="供应商额度(元)" prop="defCustQuoat">
          <el-input
            v-model="amountDialogList.defCustQuoat"
            class="br-input-large"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button class="br-btn template-reset-btn" size="small" @click="amountDialogVisible = false">
          取 消
        </el-button>
        <el-button class="br-btn template-search-btn" size="small" type="primary" @click="amountSubmit()">
          确认提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAmountCoreDetail, quotaProdInsert, quotaInsert, quotaUpdate, quotaProdUpdate } from '@/api/tradeFinancing'
export default {
  data() {
    const defCustQuoatValidator = (rule, value, callback) => {
      this.defCustQuoatValidatorCallback(rule, value, callback)
    }// 供应商默认额度校验规则
    const quotaSumValidator = (rule, value, callback) => {
      this.quotaSumValidatorCallback(rule, value, callback)
    }// 总额度校验规则
    const quotaProdSumValidator = (rule, value, callback) => {
      this.quotaProdSumValidatorCallback(rule, value, callback)
    }// 产品专项额度-总额度校验规则
    return {
      productDialogVisible: false,
      amountDialogVisible: false,
      loadingFlag: false,
      detailList: [],
      isEdit: true,
      productTitle: '添加产品专项额度',
      productDialogList: {
        id: '', // 新增不要编辑要
        prodId: '', // 产品编号
        prodName: '', // 产品名称
        bankProdCode: '', // 银行产品编码
        quotaProdSum: ''// 额度总额
      }, // 产品专项额度弹窗
      amountDialogRule: {
        bankName: [{ required: true, message: '请选择资金方', trigger: 'blur' }], // 资金方名称
        coreName: [{ required: true, message: '请选择核心企业', trigger: 'blur' }], // 核心企业名称
        quotaDue: [{ required: true, message: '请选择核心企业额度到期日', trigger: 'blur' }], // 额度到期日,
        groupType: [{ required: true, message: '请选择集团模式', trigger: ['blur', 'change'] }], // 集团模式
        group: [{ required: true, message: '请选择集团名称', trigger: ['blur', 'change'] }], // 集团模式
        quotaSum: [{ validator: quotaSumValidator, trigger: 'blur' }], // 额度总额
        defCustQuoat: [{ validator: defCustQuoatValidator, trigger: 'blur' }] // 供应商默认额度
      },
      amountDialogList: {
        id: '', // 新增不要编辑要
        bankId: '', // 资金方ID
        bankName: '', // 资金方名称
        coreId: '', // 核心企业ID
        coreName: '', // 核心企业名称
        groupType: 0, // 集团模式
        defCustDue: '', // 供应商默认到期日
        defCustQuoat: '', // 供应商默认额度
        quotaDue: '', // 额度到期日
        group: '',
        quotaSum: ''// 额度总额
      }, // 额度管理弹窗
      productDialogRules: {
        prodId: [{ required: true, message: '请选择产品名称', trigger: 'change' }],
        quotaProdSum: [{ required: true, message: '请输入总额度', trigger: 'blur' }, { validator: quotaProdSumValidator, trigger: 'blur' }]
      },
      dateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  computed: {
    productNameList() {
      return this.$store.state.tradeFinancing.productNameList
    },
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    bankList() {
      return this.$store.state.tradeFinancing.bankList
    }
  },
  created() {
    if (this.$route.query.id) {
      this.isEdit = true
      this.selectDetail()
    } else {
      this.isEdit = false
      this.amountDialogVisible = true
    }
    if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
      this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
    }
    if (!this.$store.state.tradeFinancing.bankList.length) {
      this.$store.dispatch('tradeFinancing/querBankList')
    }
    // this.queryStartFuncList()// 额度录入--资方查询
    // this.queryCoreEnterPriseList()// 额度录入--核心企业查询
  },
  methods: {
    quotaProdSumValidatorCallback(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入总额度'))
      } else if (value * 1 > this.detailList.quotaSum * 1) {
        callback(new Error('产品额度必须小于等于当前核心企业总额度'))
      } else {
        callback()
      }
    },
    defCustQuoatValidatorCallback(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入供应商额度'))
      } else if (value * 1 > this.amountDialogList.quotaSum * 1) {
        callback(new Error('供应商额度必须小于等于核心企业对应该资金方的总额度'))
      } else {
        callback()
      }
    },
    quotaSumValidatorCallback(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入总额度'))
      } else {
        if (this.amountDialogList.defCustQuoat !== '') {
          const dialogRef = this.$refs.amountDialog
          dialogRef.validateField('defCustQuoat')
        }
        callback()
      }
    },
    selectDetail() {
      this.loadingFlag = true
      getAmountCoreDetail({ id: this.$route.query.id }, res => {
        this.loadingFlag = false
        this.detailList = res.data
        if (!this.$store.state.tradeFinancing.productNameList.length) {
          // 通过资金方id和核心企业id查询产品名
          this.$store.dispatch('tradeFinancing/queryProductNameList', { bankId: res.data.bankId, coreId: res.data.coreId })
        }
      }, () => {
        this.loadingFlag = false
      })
    },
    // 弹窗里--核心企业查询select change
    coreEnterPriseListChange(e) {
      this.amountDialogList['coreName'] = this.coreEnterPriseList.filter(item => {
        return item['cmp_id'] === e
      })[0]['cmp_name']
    },
    // 弹窗里--资方查询--select change
    startFuncListChange(e) {
      this.dialogList['bankName'] = this.bankList.filter(item => {
        return item['id'] === e
      })[0]['funderName']
      const cmpunicode = this.bankList.filter(item => {
        return item['id'] === e
      })[0]['funderUnicode']
      if (this.dialogList.groupType === 1) this.groupTypeChange(0)
      this.getGroupSelectList(cmpunicode)
    },
    // 产品专项额度弹窗里产品下拉变化
    productChange(e) {
      const result = this.productNameList.filter(item => {
        return item['proId'] === e
      })[0]
      // 产品名称
      this.productDialogList['prodName'] = result['proName']
      // 资金方产品编号
      this.productDialogList['bankProdCode'] = result['proFundNo']
      // id
      this.productDialogList['prodKey'] = result['id']
    },
    // 集团模式：是-显示集团名称，选择完集团名称自动带出总额度到期日供应商额度，否-隐藏集团名称
    groupTypeChange(e) {
      if (e === 0) {
        this.dialogList['group'] = ''
        this.dialogList['groupName'] = ''
        this.dialogList['groupUnicode'] = ''
        this.dialogList['quotaSum'] = ''
        this.dialogList['quotaDue'] = ''
        this.dialogList['defCustQuoat'] = ''
        this.dialogList['groupQuotaId'] = ''
        this.dialogQuotaSumMax = ''
        this.dateOptions = {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          }
        } //  今天之后 包括今天
      }
    },
    quotaSumInput(e) {
      if (this.dialogList.groupType === 1 && e > this.dialogQuotaSumMax) {
        this.dialogList['quotaSum'] = this.dialogQuotaSumMax
      }
    },
    // 弹窗里--总额度quotaSum change 供应商额度自动代入
    quotaChange(e) {
      if (!this.dialogList.defCustQuoat) {
        this.dialogList.defCustQuoat = e
        this.$forceUpdate()
      }
    },
    // 添加产品专项额度
    productAdd() {
      this.showProductDialog('添加产品专项额度')
    },
    // 产品专项额度
    productSubmit() {
      this.productDialog = this.$refs['productDialog']
      this.productDialog.validate(valid => {
        if (valid) {
          // 产品额度：必须<=当前核心企业总额度
          if (this.productDialogList.quotaProdSum * 1 > this.detailList.quotaSum * 1) {
            this.$message({
              type: 'error',
              message: '产品额度必须小于等于当前核心企业总额度'
            })
            return
          }
          this.productDialogVisible = false
          // 新增
          const params = JSON.parse(JSON.stringify(this.productDialogList))
          delete params.prodName
          delete params.bankProdCode
          if (this.productTitle === '添加产品专项额度') {
            delete params.id
            params.quotaId = this.detailList.id
            quotaProdInsert(params, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: this.productTitle + '成功'
                })
                this.selectDetail()
              }
            })
          } else {
            delete params.prodId
            // 编辑
            quotaProdUpdate(params, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: this.productTitle + '成功'
                })
                this.selectDetail()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 额度调整
    amountSubmit() {
      this.amountDialog = this.$refs['amountDialog']
      this.amountDialogList.defCustDue = this.amountDialogList.quotaDue
      this.amountDialog.validate(valid => {
        if (valid) {
          // 供应商额度defCustQuoat<=总额度quotaSum
          if (this.amountDialogList.defCustQuoat * 1 > this.amountDialogList.quotaSum) {
            this.$message({
              type: 'error',
              message: '供应商额度必须小于等于核心企业对应该资金方的总额度'
            })
            return
          }
          this.amountDialogVisible = false
          const params = JSON.parse(JSON.stringify(this.amountDialogList))
          delete params.group
          if (this.$route.query.id) {
            // 编辑
            delete params.groupType
            delete params.groupName
            delete params.groupUnicode
            quotaUpdate(params, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '额度调整成功'
                })
                this.selectDetail()
              }
            })
          } else {
            // 新增
            delete params.id
            quotaInsert(params, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '额度录入成功'
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 产品专项额度点详情
    productDetail(row) {
      this.$router.push({
        path: '/productManage/productDetail',
        name: 'productDetail',
        query: {
          type: '1',
          proId: row.prodId
        }
      })
    },
    // 产品专项额度点额度调整
    productEdit(row) {
      this.showProductDialog('调整产品专项额度')
      this.productDialogList = {
        id: row.id, // 新增不要编辑要
        quotaId: row.quotaId,
        prodId: row.prodId, // 产品编号
        prodName: row.prodName, // 产品名称
        bankProdCode: row.bankProdCode, // 银行产品编码
        quotaProdSum: row.quotaProdSum// 额度总额
      }
    },
    // 展示产品专项额度弹窗
    showProductDialog(title) {
      this.productTitle = title
      this.productDialogVisible = true
    },
    // 核心企业额度调整
    amountEdit() {
      this.amountDialogVisible = true
      this.amountDialogList = {
        id: this.detailList.id, // 新增不要编辑要
        group: { groupName: this.detailList.groupName, groupUnicode: this.detailList.groupUnicode },
        groupUnicode: this.detailList.groupUnicode, // 集团名称
        groupQuotaId: this.detailList.groupQuotaId,
        groupName: this.detailList.groupName,
        bankId: this.detailList.bankId, // 资金方ID
        bankName: this.detailList.bankName, // 资金方名称
        coreId: this.detailList.coreId, // 核心企业ID
        coreName: this.detailList.coreName, // 核心企业名称
        groupType: this.detailList.groupType, // 集团模式
        defCustDue: this.detailList.defCustDue, // 供应商默认到期日
        defCustQuoat: this.detailList.defCustQuoat, // 供应商默认额度
        quotaDue: this.detailList.quotaDue, // 额度到期日
        quotaSum: this.detailList.quotaSum // 额度总额
      }
      if (this.amountDialogList.groupType === 1) {
        const cmpunicode = this.bankList.filter(item => {
          return item['id'] === this.detailList.bankId
        })[0]['funderUnicode']
        this.getGroupSelectList(cmpunicode)
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
  .amount-hxqy-detail {
    background-color: #fff;
    padding: 10px 20px;

    .br-input {
      width: 100%;
    }

    .table-button-e {
      margin-left: 20px;
    }

    .title {
      line-height: 40px;
      font-weight: 600;
      font-size: 16px;
    }

    .info-list {
      padding: 10px;

      li {
        float: left;
        width: 32%;
        line-height: 30px;
        font-size: 14px;
        padding: 0 4px;

        .label {

        }

        .info {
          color: #606266;
        }
      }
    }

    .table-box {
      padding: 10px;
    }
  }
</style>
